import React from "react";
import "./Home.css";
import logo from "../../assets/imgs/DiningRD-Logo-Color_Tag.png";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Link, useOutletContext } from "react-router-dom";
import BaseContent from "../shared/baseContent/BaseContent";
import Button from "@mui/material/Button";
import { loginRequest } from "authConfig";
import { List, ListItem } from "@mui/material";

export default function Home() {
  const currentUserData = useOutletContext();
  const { instance } = useMsal();

  const showStartAMealButton = currentUserData?.userRoles?.some((role) => ['Tableside_Setup_User', 'Admin', 'Menu_Team'].includes(role?.dmRoleName));

  const handleLogin = async () => {
    await instance.loginRedirect({ ...loginRequest, prompt: "login" });
  };

  return (
    <BaseContent disableBack={true}>
      <AuthenticatedTemplate>
        <div className="authenticatedHomePage">
          <div className="logoHeader">
            <img className="centerLogo" src={logo} />
          </div>
            <>
            <div style={{width: '100%'}}>
              <List>
                <ListItem className="homeButton">
                  <Button
                    component={Link}
                    to="/takeorder/residents"
                    color={"primary"}
                    className="baseButton"
                    variant="contained"
                    sx={{width: '100%', height: '2.25rem'}}>
                    TAKE ORDERS
                  </Button>
                </ListItem>

                { showStartAMealButton && (
                  <ListItem className="homeButton">
                    <Button
                      component={Link}
                      to="/meal"
                      color={"primary"}
                      className="baseButton"
                      variant="contained"
                      sx={{width: '100%', height: '2.25rem'}}>
                      START A MEAL
                    </Button>
                  </ListItem>
                )}
              </List>
            </div>
            <div className="activeUserContainer">
              {/*eslint-disable-next-line no-undef */}
              <p>v {process.env.REACT_APP_VERSION}</p>
              <p>{currentUserData?.emailAddress || ""}</p>
            </div>
          </>
        </div>
      </AuthenticatedTemplate>
      {/* Should never see this as the base route is protected by forced redirect login */}
      <UnauthenticatedTemplate>
        <div className="unauthenticatedHomePage">
          <div className="logoHeader">
            <img className="centerLogo" src={logo} />
            <div className="loginMessage">
              <Button
                component={Link}
                color={"primary"}
                className="baseButton"
                variant="contained"
                onClick={handleLogin}>
                Click here to login
              </Button>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </BaseContent>
  );
}
