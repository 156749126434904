import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Home from "../components/home/Home";
import Base from "../components/base/Base";

// Error / NotFound / Forbidden Imports
import ErrorPage from "../components/shared/errorPage/ErrorPage";
import NotFound from "../components/shared/notFound/NotFound";
import Forbidden from "../components/shared/forbidden/Forbidden";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "authConfig";
import ProtectedRoute from "./ProtectedRoute";
import { PAGE_ROLES } from "components/shared/constants";
import TakeOrder from "components/takeOrder/takeOrderBase/TakeOrder";
import Residents from "components/takeOrder/residents/Residents";
import Guest from "components/takeOrder/guest/Guest";
import CooksTicket from "components/takeOrder/cooksTicket/CooksTicket";
import HeldOrders from "components/takeOrder/heldOrders/HeldOrders";
import Submitted from "components/takeOrder/submitted/Submitted";
import Login from "components/shared/login/Login";
import StartMeal from "components/startMeal/StartMeal";

const { TABLESIDE_USER, TABLESIDE_SETUP_USER } = PAGE_ROLES;

const routesArray = [
  {
    path: "/",
    element: <Base />, 
    errorElement: <ErrorPage />,
    children: [
      { path: "", element: 
        (<MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={{...loginRequest, prompt:'login'}}
        >
          <Home />
        </MsalAuthenticationTemplate>)
      },
      { 
        path: "takeorder",
        element: (
          <ProtectedRoute allowedRoles={TABLESIDE_USER}>
            <TakeOrder />
          </ProtectedRoute>
        ),
        children: [
          { path: "residents", 
            element: <Residents />,
            children: [
              {
                path: "meals/:residentId",
                element: <TakeOrder />
              },
              {
                path: "order/:residentId/:tableSideSetupId",
                element: <TakeOrder />
              }
            ]
          },
          {
            path: "guest", 
            element: <Guest />,
            children: [
              {
                path: "order",
                element: <TakeOrder />
              }
            ]
          },
          { path: "cooksticket", element: <CooksTicket /> },
          { path: "heldorders", element: <HeldOrders /> },
          { path: "submitted", element: <Submitted /> }
        ]
      },
      {
        path: "meal",
        element: (
          <ProtectedRoute allowedRoles={TABLESIDE_SETUP_USER}>
            <StartMeal />
          </ProtectedRoute>
        )
      },
      { path: "forbidden", element: <Forbidden /> },
      { path: "login", element: <Login /> },
      { path: "*", element: <NotFound /> },
    ]
  }
];

const convertRoutes = (routes) => {
  return routes.map(({ path, element, errorElement, children }) => (
    <Route path={path} key={path} element={element} errorElement={errorElement}>
      {children && convertRoutes(children)}
    </Route>
  ));
};

const routeElements = convertRoutes(routesArray);

export const router = createBrowserRouter(
  createRoutesFromElements(routeElements)
);