import { 
  Box, 
  Button, 
  InputAdornment, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemSecondaryAction, 
  ListItemText, 
  TextField, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SelectDiningArea from "components/shared/selectDiningArea/SelectDiningArea";
import React, { useEffect, useState } from "react";
import "./Residents.css";
import "../../../index.css";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import ResidentInfo from "./ResidentInfo";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectLastDiningArea, setFacilityDiningAreas, setLastDiningArea } from "store/slices/diningAreaSlice/DiningAreaSlice";
import { EditSkeleton } from "components/admin/admin.overlays";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import { useGetResidentActiveMealsByIdQuery, useGetResidentsAllQuery, useGetResidentsByAreaIdQuery } from "store/apis/ResidentsApi";
import { useGetPendingMealsQuery } from "store/apis/MealsApi";
import SelectMeal from "components/shared/selectMeal/SelectMeal";
import { 
  selectLastMealSelected, 
  selectLastTableFilter, 
  selectLastWingFilter, 
  selectOrderWorkflow, 
  setLastMealSelected, 
  setLastTableFilter, 
  setLastWingFilter, 
  setOrderWorkflow 
} from "store/slices/orderSlice/OrderSlice";
import dayjs from "dayjs";
import SelectTable from "components/shared/selectTable/SelectTable";
import SelectWing from "components/shared/selectWing/SelectWing";
import { useGetSubmittedOrdersQuery } from "store/apis/OrdersApi";
import MealIcon from "@mui/icons-material/Restaurant";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import InfoModal from "components/shared/infoModal/InfoModal";

export default function Residents({heldOrders}) {


  const [diningOpen, setDiningOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [mealOpen, setMealOpen] = useState(false);
  const lastMeal = useSelector(selectLastMealSelected);
  const [selectedMealId, setSelectedMealId] = useState(lastMeal?.setupId || 0);
  const [selectedMeal, setSelectedMeal] = useState(lastMeal?.mealName || '');
  const [selectedDiningAreaId, setSelectedDiningAreaId] = useState(0);
  const [selectedDiningAreaName, setSelectedDiningAreaName] = useState("");
  const [tableOpen, setTableOpen] = useState(false);
  const [tableOptions, setTableOptions] = useState([]);
  const lastTableFilter = useSelector(selectLastTableFilter);
  const [selectedTable, setSelectedTable] = useState(lastTableFilter || 'all');
  const [wingOpen, setWingOpen] = useState(false);
  const [wingOptions, setWingOptions] = useState([]);
  const lastWingFilter = useSelector(selectLastWingFilter);
  const [selectedWing, setSelectedWing] = useState(lastWingFilter || 'all');
  const [residents, setResidents] = useState([]);
  const [shownResidents, setShownResidents] = useState([]);
  const [selectedResidentForInfo, setSelectedResidentForInfo] = useState(0);
  const [selectedResidentForOrder, setSelectedResidentForOrder] = useState(0);
  const orderWorkflow = useSelector(selectOrderWorkflow);
  const [orderMethod, setOrderMethod] = useState(orderWorkflow || "Meal");
  const lastDiningArea = useSelector(selectLastDiningArea);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorText, setErrorText] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: diningAreas,
    isFetching: fetchingAreas,
  } = useGetDiningAreasQuery();

  const {
    data: pendingMeals,
    isFecthing: fetchingMeals
  } = useGetPendingMealsQuery();

  const {
    data: allSubmittedOrders,
    isFetching: fetchingOrders,
    isError: errorOrders,
    error: errorOrdersMsg
  } = useGetSubmittedOrdersQuery(
    { refetchOnMountOrArgChange: true,  skip: !residents?.length }
  );

  useEffect(() => {
    dispatch(setFacilityDiningAreas(diningAreas));
    diningAreaSelected(lastDiningArea?.diningAreaId || diningAreas?.[0]?.id, lastDiningArea?.diningAreaName || diningAreas?.[0]?.name);
  }, [diningAreas]);

  useEffect(() => {
    if (pendingMeals) {
      mealSelected(lastMeal?.setupId || pendingMeals?.[0]?.id, lastMeal?.mealName || `${pendingMeals?.[0]?.mealName} (${dayjs(pendingMeals?.[0]?.diningDate).format('dddd [-] MM/DD/YYYY')})`);
      dispatch(setLastMealSelected({setupId: selectedMealId, mealName: selectedMeal}));
    }

  }, [pendingMeals])

  const handleInfoClose = () => {
    setInfoOpen(false);
  }

  const diningAreaSelected = (diningAreaId, diningAreaName) => {
    setDiningOpen(false);
    if (!diningAreaId && !diningAreaName) {
      return;
    }
    setSelectedDiningAreaId(diningAreaId);
    setSelectedDiningAreaName(diningAreaName);
    const selectedDiningArea = diningAreas?.find((area) => area.id === diningAreaId);
    if (!selectedDiningArea?.tables.find((table) => table?.name === selectedTable)) {
      setSelectedTable('all');
    }
    setTableOptions(selectedDiningArea?.tables);
    dispatch(setLastDiningArea({ diningAreaId: diningAreaId, diningAreaName: diningAreaName }))
  }

  const tableSelected = (tableName) => {
    setTableOpen(false);
    if(tableName) {
      setSelectedTable(tableName);
      dispatch(setLastTableFilter(tableName));
    }
  }

  const wingSelected = (wingName) => {
    setWingOpen(false);
    if (wingName) {
      setSelectedWing(wingName);
      dispatch(setLastWingFilter(wingName));
    }
  }

  const mealSelected = (setupId, mealName) => {
    setMealOpen(false);
    if (setupId && mealName) {
      setSelectedMealId(setupId);
      setSelectedMeal(mealName);
      dispatch(setLastMealSelected({setupId: setupId, mealName: mealName}));
    }
  }

  const { data: diningAreaResidents } = useGetResidentsByAreaIdQuery({diningAreaId: selectedDiningAreaId, setupId: selectedMealId},
    { refetchOnMountOrArgChange: true, skip: !selectedDiningAreaId || selectedDiningAreaId === 'all' || !selectedMealId }
  );

  const { 
    data: allResidents, 
    isFetching: fetchingResidents, 
    isError: errorResidents,
    error: errorResidentsMsg
  } = useGetResidentsAllQuery({ refetchOnMountOrArgChange: true });

  const isLoading = fetchingAreas || fetchingResidents || fetchingMeals || fetchingOrders;

  useEffect(() => {
    setResidents(diningAreaResidents);
  }, [diningAreaResidents])

  const filterResidents = (list, searchString) => {
    return list?.filter((resident) => {
      return ((resident?.name?.toLowerCase().indexOf(searchString.toLowerCase()) !== -1) || 
      (resident?.roomNumber?.toLowerCase()?.indexOf(searchString.toLowerCase()) !== -1));
    }); 
  }

  const searchResidents = (searchString) => {
    let filteredResidents = selectedDiningAreaId === 'all' ? filterResidents(allResidents, searchString) : filterResidents(residents, searchString);

    if (filteredResidents?.length === 0 && searchString === '') {
      filteredResidents =  selectedDiningAreaId === 'all' ? [...allResidents] : [...residents];
    }
    setShownResidents([...filteredResidents]);
  }

  useEffect(() => {
    if (!isLoading && (errorOrders || errorResidents)) {
      //use set to prevent duplicate error messages and convert to Array and join later
      const modalTextSet = new Set();
      if (errorOrdersMsg?.data?.messages?.length) {
        errorOrdersMsg?.data?.messages?.forEach((msg) => modalTextSet.add(msg));
      }

      if (errorResidentsMsg?.data?.messages?.length) {
        errorResidentsMsg?.data?.messages?.forEach((msg) => modalTextSet.add(msg));
      }

      setErrorText(Array.from(modalTextSet).join("\n"));
      setErrorOpen(true);
    }
  }, [errorOrders, errorResidents, isLoading]);

  const handleErrorClose = () => {
    setErrorOpen(false);
  }

  const checkResidentModifiers = (resident) => {
    if (resident?.isIsolation) {
      return `${resident.name} ( * Isolation )`;
    } else if (resident.isOutOfBuilding) {
      return `${resident.name} ( * Out of Building )`;
    } else {
      return resident.name;
    }
  }

  const { data: activeMeals } = useGetResidentActiveMealsByIdQuery(selectedResidentForOrder?.id,
    { skip: !selectedResidentForOrder?.id}
  );
  
  useEffect(() => {
    if (activeMeals && selectedResidentForOrder?.id) {
      takeResidentOrder(selectedResidentForOrder?.id);
    }
  }, [activeMeals]);

  const takeResidentOrder = (residentId) => {
    dispatch(setOrderWorkflow(orderMethod));
    if (activeMeals && residentId) {
      const currentFlag = !!pendingMeals?.find((meal) => meal?.id === selectedMealId)?.isActive;

      if (orderMethod === "Meal") {
        navigate({
        pathname: `/takeorder/residents/order/${residentId}/${selectedMealId}`,
          search: `?current=${currentFlag}`
        });
      } else if (orderMethod === "Resident") {
        navigate({
          pathname: `/takeorder/residents/meals/${residentId}`
        });
      }
    }
  }

  const onResidentInfoClick = (resident) => {
    setSelectedResidentForInfo(resident);
    setInfoOpen(true);
  }

  useEffect(() => {
    if (allResidents?.length && wingOptions?.length === 0) {
      const wingOptions = new Set();
      allResidents.forEach((res) => {
        if (res?.wing) {
          wingOptions.add(res.wing?.toLowerCase());
        }
      });
      setWingOptions(Array.from(wingOptions));
    }

    if (allResidents?.length && selectedDiningAreaId === 'all') {
      if (selectedWing === 'all') {
        setShownResidents(() => [...allResidents]);
      } else {
        setShownResidents(() => [...allResidents.filter((res) => res.wing?.toUpperCase() === selectedWing?.toUpperCase())]);
      }
    } else if (residents?.length) {
      if (selectedTable === 'all' && selectedWing === 'all') {
        setShownResidents(() => [...residents]);
      } else {
        let filteredRes = [...residents]; // start with all
        if (selectedTable !== 'all') {
          const mealName = selectedMeal?.toLowerCase()?.split(" ")[0];
          filteredRes = filteredRes.filter((res) => res[`${mealName}TableName`]?.toUpperCase() === selectedTable?.toUpperCase());
        }
        if (selectedWing !== 'all') {
          filteredRes = filteredRes.filter((res) => res.wing?.toUpperCase() === selectedWing?.toUpperCase());
        }
        setShownResidents([...filteredRes]);
      }
    }
  }, [residents, selectedDiningAreaId, allResidents, selectedWing, selectedTable]);

  const renderShownResidents = () => {
    const residentUIList = [];

    shownResidents?.forEach((resident) => {
      let hasHeldOrder = false;
      heldOrders?.forEach((heldOrder) => {
        if (heldOrder?.order?.residentId === resident.id && heldOrder?.order?.tableSideSetupId === selectedMealId) {
          hasHeldOrder = true;
        }
      });
      if (!hasHeldOrder) {
        let hasOrderTaken = allSubmittedOrders?.some((order) => order.id === resident.id && order.tableSideSetupId === selectedMealId);
        residentUIList.push( 
          <ListItem
            key={resident.id} 
            style={{backgroundColor: hasOrderTaken ? 'lightgray' : 'white', cursor: "pointer"}} 
          >
            <ListItemIcon onClick={() => onResidentInfoClick(resident)}> <PersonIcon style={{transform: "scale(1.5)"}} /> </ListItemIcon>
            <ListItemText onClick={() => setSelectedResidentForOrder(resident)} 
              primary={<span className="resident-name">{checkResidentModifiers(resident)}</span>} />
            <ListItemSecondaryAction>
              <Typography>{resident?.roomNumber || ''}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        );
      }
    });

    return residentUIList;
  }
  
  return (
    <>
      {isLoading ? (
        <EditSkeleton />
      ) : (
        <div className="paperContent customScrollbar" style={{backgroundColor: "#FFF"}}>
          <Grid container justifyContent="center"  spacing={1} sx={{marginBottom: ".5rem"}}>
            <Grid size={6}>
              <Button 
                id="meal-btn"
                fullWidth
                variant="contained"
                onClick={() => setMealOpen(true)}    
                endIcon={<ExpandIcon />}
              >
                Meal: {selectedMeal}
              </Button>
              <SelectMeal 
                open={mealOpen}
                pendingMeals={pendingMeals}
                itemSelected={mealSelected}
                title="Select Meal"
              />
            </Grid>
            <Grid size={6}>
              <Button
                id="dining-btn"
                fullWidth
                variant="contained"
                onClick={() => {
                  setOrderMethod((prev) => {
                    dispatch(setOrderWorkflow(prev === "Meal" ? "Resident" : "Meal"));
                    return prev === "Meal" ? "Resident" : "Meal"});
                }}
              >
                  Take Orders By: {orderMethod}
                  {orderMethod === "Meal" ? <MealIcon className="workflowIcon" /> : <PersonIcon className="workflowIcon" />}
                </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={1} sx={{marginBottom: ".5rem"}}>
            <Grid size={6}>
              <Button 
                id="dining-btn"
                fullWidth 
                variant="contained" 
                onClick={() => setDiningOpen(true)}
                endIcon={<ExpandIcon />}
              >
                Dining Area: {selectedDiningAreaName}
              </Button>
              <SelectDiningArea
                open={diningOpen} 
                diningAreas={diningAreas}  
                itemSelected={diningAreaSelected}
                title="Select Dining Area"
                enableAll={true}
                />
            </Grid>
            <Grid size={3}>
              <Button   
                id="dining-btn"
                fullWidth 
                variant="contained" 
                onClick={() => setTableOpen(true)}
                endIcon={<ExpandIcon />}
              >
                Table: {selectedTable}
              </Button>
              <SelectTable 
                open={tableOpen}
                tables={tableOptions}
                itemSelected={tableSelected}
                title="Select Table"
                enableAll={true}
              />
            </Grid>
            <Grid size={3}>
              <Button 
                id="dining-btn"
                fullWidth 
                variant="contained" 
                onClick={() => setWingOpen(true)}
                endIcon={<ExpandIcon />}
              >
                Wing: {selectedWing}
              </Button>
              <SelectWing
                open={wingOpen}
                wings={wingOptions}
                itemSelected={wingSelected}
                title="Select Wing"
                enableAll={true}
                endIcon={<ExpandIcon />}
              />
            </Grid>
          </Grid>  

          <Grid container spacing={2} flexWrap="nowrap" sx={{margin: ".75rem 0"}}>
            <Grid width="-webkit-fill-available">
              <TextField
                fullWidth
                variant="standard"
                type="search"
                onChange={(event) => searchResidents(event.target.value)}
                placeholder="Search by Name, Room Number"
                sx={{
                  "& .MuiInput-root::before": {
                    borderBottom: "2px solid rgba(0, 0, 0, .75)"
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{color: "var(--tealColor)"}} />
                    </InputAdornment>
                  )
                }}
              >
              </TextField>  
            </Grid>
          </Grid>
          <Box 
            display="flex" 
            flexWrap="wrap" 
            justifyContent="flex-end" 
          >
            <Typography variant="body1"
              sx={{
                padding: "0 .5rem 0 .5rem",
                backgroundColor: "#9ec6c7",
                color: "#000",
                border: "1px solid rgba(0, 0, 0, .4)",
                borderRadius: ".5rem .5rem 0 0"
              }}
            >
              Room #
            </Typography>
          </Box> 
          <List sx={{paddingY: 0}}>
            {renderShownResidents()}
          </List>
          <ResidentInfo
            open={infoOpen}
            handleInfoClose={handleInfoClose}
            residentId={selectedResidentForInfo?.id}
            setupId={selectedMealId}
            showPhoto={!!selectedResidentForInfo?.photoOnTrayCard}
          />
          {errorOpen && (
            <InfoModal title={"Error"} open={errorOpen} modalText={errorText} handleClose={handleErrorClose} />
          )}
        </div>
      )}
    </>
  )
}