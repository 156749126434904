import { 
  Box, 
  Button, 
  Card, 
  Dialog, 
  IconButton, 
  MenuItem, 
  Select, 
  Slide, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Typography 
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { forwardRef, useEffect, useState } from "react";
import "./SubRecipeModal.css";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BaseInput from "../baseForm/BaseInput";
import { useForm } from "react-hook-form";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SubRecipeModal({open, selectedRecipe, currentSubRecipes = [], specialNote = "", handleClose}) {
  const [selectedSubRecipes, setSelectedSubRecipes] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all"
  });

  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = methods;

  useEffect(() => {
    // we only want the ones pertaining to the loaded item's subs
    setSelectedSubRecipes(currentSubRecipes.filter((sub) => sub.parentRecipeId === selectedRecipe.recipeId));
    if (specialNote?.length) {
      setValue("specialNote", specialNote);
    }
  }, [currentSubRecipes, specialNote])

  const renderQuantity = (maxQuantity) => {
    const sizes = [];
    const servingSizes = [];

    for (let i = 0; i <= maxQuantity; i++) {
      servingSizes.push(i);
    }

    servingSizes.forEach((size) => {
      sizes.push(
        <MenuItem key={size} value={size}>
          <span className="general-text">{size}</span>
        </MenuItem>
      );
    });

    return sizes;
  }

  const selectQuantity = (item, value) => {
    updateSelectedList(item, !!value, value);
  }

  const handleSubRecipeChange = (item, checked, value) => {
    updateSelectedList(item, checked, value || 1)
  }

  const updateSelectedList = (item, checked, value) => {
    if (checked) {
      setSelectedSubRecipes((prev) => [...prev.filter((old) => old.recipeId !== item.recipeId), 
        {
          parentRecipeId: item.parentRecipeId,
          quantity: value,
          recipeId: item.recipeId,
          recipeName: item.recipeName,
          isAlternateItem: item.isAlternateItem
        }
      ]);
    } else {
      setSelectedSubRecipes((prev) => [...prev.filter((old) => old.recipeId !== item.recipeId)]);
    }
  }

  const renderTableItems = (recipe) => {
    const subMenuItems = [];
    const tempSubList = recipe.subRecipeList?.map((sub) => {
      return {
        parentRecipeId: sub.parentRecipeId,
        quantity: sub.quantity,
        recipeId: sub.recipeId,
        recipeName: sub.recipeName,
        isAlternateItem: false
      }
    });

    tempSubList.forEach((sub) => {
      let flag = false;

      const foundSubRecipe = selectedSubRecipes?.find((selected) => selected?.recipeId === sub.recipeId
        && selected?.isAlternateItem === recipe.isAlternateRecipe && selected?.parentRecipeId === recipe.recipeId);
      const selectedQty = foundSubRecipe?.quantity || 0;

      sub.isAlternateItem = recipe.isAlternateRecipe;

      if (foundSubRecipe) { flag = true; }

      subMenuItems.push(
        <TableRow 
          key={sub.recipeId} 
          sx={{
            borderBottom: flag ? "1px solid darkgray" : "1px solid rgba(128, 128, 128, .5)",
            backgroundColor: flag ? "#cee2e3" : "white",  
            display: "grid", 
            gridTemplateColumns: "4fr 1fr", 
            width: "100%",
            borderRadius: "1rem",
            justifyContent: "flex-start",
            margin: ".25rem 0"
          }}
        >
          <TableCell 
            className="noBorderCell"
            sx={{
              paddingLeft: ".25rem !important", 
              paddingRight: "0px !important", 
              cursor: "pointer", 
              display: "flex", 
              width: "-webkit-fill-available"
            }} 
            onClick={() => handleSubRecipeChange(sub, !flag, selectedQty)}>
            <Typography sx={{fontSize: "1rem", alignSelf: "center", maxWidth: "375px"}}>{sub.recipeName}</Typography>
          </TableCell>
          <TableCell className="noBorderCell" sx={{paddingLeft: "0", marginLeft: "auto !important"}}>
            <Select
              variant="standard"
              sx={{marginTop: ".25rem"}}
              value={selectedQty}
              onChange={(event) => selectQuantity(sub, event.target.value)}
              onClick={(e) => e.stopPropagation()}
            >
              {renderQuantity(recipe?.maxNumberOfServings || 5)}
            </Select>
          </TableCell>
        </TableRow>
      )
    });
    
    return subMenuItems;
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    >
      <Box 
        className="customScrollbar" 
        sx={{
          backgroundColor: "#f4f9f9", 
          border: "2px solid #737373", 
          borderRadius: ".5rem", 
          overflowY: "auto",
        }}
      >
        <Box sx={{display: "flex", justifyContent: "flex-start", alignItems: "center", flex: "1 0 100%"}}>
          <Typography variant="h5" sx={{padding: "1rem 0 0 1.5rem"}}>{selectedRecipe?.recipeName}</Typography>
          <IconButton 
            sx={{
              marginRight: "1rem", 
              marginLeft: "auto", 
              color: "red", 
              height: "2rem", 
              width: "2rem"}} 
              onClick={() => handleClose(selectedRecipe.recipeId, [...selectedSubRecipes], specialNote)} 
              aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{padding: ".5rem 1.5rem .25rem 1.5rem", display: "flex", flexWrap: "wrap", flex: "1 0 100%"}}>
          <Card sx={{padding: ".5rem"}}>
            <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column">
              <Table>
                <TableBody>
                  {!!selectedRecipe?.subRecipeList && renderTableItems(selectedRecipe)}
                </TableBody>
              </Table>
            </Grid>
          </Card>
        </Box>
        <Box sx={{padding: ".5rem 1.5rem 1.5rem 1.5rem", display: "flex", flexWrap: "wrap", flex: "1 0 100%"}}>
            <Typography sx={{width: "100%"}} variant="h5" >Special Note</Typography>
            <BaseInput 
                control={control}
                errors={errors}
                id="specialNote"
                name="specialNote"
                multiline
                variant="outlined"
                rows="1"
                fullWidth
                margin="normal"
                sx={{backgroundColor: "white !important"}}
                validationProps={{
                  maxLength: {
                    value: 32,
                    message: "Please enter 32 characters or less"
                  }
                }}
              />
        </Box>
        <Box sx={{display: "flex", justifyContent: "flex-end", padding: "0 1.5rem"}}>
          <Button 
            sx={{
              width: "fit-content", 
              alignSelf: "flex-end", 
              fontWeight: "bold", 
              marginBottom: "1rem", 
              color: "#FFF"
            }} 
            variant="contained" 
            onClick={() => { 
              if (errors?.specialNote) { return; }
              handleClose(selectedRecipe.recipeId, selectedSubRecipes, getValues("specialNote"));
            }}
            endIcon={<AddCircleIcon sx={{marginBottom: ".25rem", transform: "scale(1.25)"}} />}
          >
            Add
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}