import { Button, Divider, List, ListItem, Typography } from "@mui/material"
import Grid from "@mui/material/Grid2";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetResidentActiveMealsByIdQuery, useGetResidentInfoByIdQuery } from "store/apis/ResidentsApi";
import { EditSkeleton } from "components/admin/admin.overlays";
import CheckIcon from "@mui/icons-material/Check";

export default function ActiveMeals() {
  const { residentId } = useParams();
  const [setupId, setSetupId] = useState(null);
  
  const { 
    data: residentInfo,
    isFetching: fetchingResident
  } = useGetResidentInfoByIdQuery({residentId: residentId, setupId: setupId},
    { skip: !residentId || !setupId }
  );

  const { 
    data: activeMeals,
    isFetching: fetchingMeals 
  } = useGetResidentActiveMealsByIdQuery(residentId,
    { refetchOnMountOrArgChange: false, skip: !residentId }
  );

  useEffect(() => {    
    //just need any setupId to make the resident info call for resident name
    if (activeMeals && activeMeals?.days?.length) {
      let firstSetupId = null;
      for (const meal of activeMeals.days[0].meals) {
        if (meal.setupId > 0) {
          firstSetupId = meal.setupId;
          break;
        }
      }

      if (firstSetupId) {
        setSetupId(firstSetupId);
      }
    }
  }, [activeMeals])

  const navigate = useNavigate();

  const handleMealClick = (meal) => () => {
    navigate({
      pathname: `/takeorder/residents/order/${residentInfo.id}/${meal.setupId}`, 
      search: `?current=${!!meal.name.includes("Current Meal")}`
    });
  }

  const renderActiveMeals = () => {
    let dayList = [];
    if (activeMeals !== null) {

      if (activeMeals?.days?.length > 0) {
        activeMeals.days.forEach((day) => {
          const mealList = [];
          day.meals.forEach((meal) => {
            
            let backgroundColor = "lightgray"; 
            let color = "#000";

            if (meal.name.includes("Current Meal")) {
              backgroundColor = "primary";
              color = "#FFF";
            } else if (meal.filler) {
              backgroundColor = "transparent !important";
            } 
            
            mealList.push(
              <Button
                key={meal.name}
                variant="contained"
                disabled={meal.filler}
                onClick={handleMealClick(meal)}
                sx={{
                  fontWeight: "bold", 
                  color: color,
                  backgroundColor: backgroundColor,
                  marginX: ".25rem"
                }}
                startIcon={meal.orderTaken ? <CheckIcon /> : null}
            >
              {meal.name}
            </Button>
            );
          });

          dayList.push(
            <ListItem key={day.date} sx={{justifyContent: "center", alignItems: "center"}}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h5" gutterBottom>
                  {day.date}
                </Typography>
                <Grid container justifyContent="center" alignItems="center">
                  {mealList}
                </Grid>
              </Grid>
            </ListItem>
          );

          if (activeMeals.days.indexOf(day) !==( activeMeals.days.length - 1)) {
            dayList.push(
              <Divider sx={{marginTop: ".25rem"}} key={`divider-${day.date}`}></Divider>
            );
          }
        })
      }
    }

    return dayList;
  }

  return (
    <>
      { fetchingMeals || fetchingResident || !residentInfo ? (
          <EditSkeleton />
        )
        : 
        (
          <div className="paperContent customScrollbar" style={{backgroundColor: "#FFF"}}>
            <Typography 
              variant="h5" 
              fontWeight="bold" 
              gutterBottom 
              align="center"
            >
              {residentInfo?.name?.split(",")[1]} {residentInfo?.name?.split(",")[0]} 
            </Typography>
            <List>
              {renderActiveMeals()}
            </List>
          </div>
        )
      }
    </>
  );
}